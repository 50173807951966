import React, { useState } from 'react';
import { Button, Modal, Upload } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const BottomAdd = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileList, setFileList] = useState([]);
  const navigate = useNavigate();

  const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleFileChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const handleFilePreview = async file => {
    try {
      if (!file.url && !file.preview) {
        const preview = await getBase64(file.originFileObj);
        file.preview = preview;
        setFileList([...fileList]); // Ensure to update fileList to trigger re-render
      }
      window.open(file.preview); // Open the preview directly
    } catch (error) {
      console.error("Error occurred while generating file preview:", error);
    }
  };
  
  
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  //   edit 
const [isModalOpenedit, setIsModalOpenedit] = useState(false);

const showModaledit = () => {
  setIsModalOpenedit(true);
};

const handleOkedit = () => {
  setIsModalOpenedit(false);
};

const handleCanceledit = () => {
  setIsModalOpenedit(false);
};

// Delete
const [isModalOpendlt, setIsModalOpendlt] = useState(false);

const showModaldlt = () => {
  setIsModalOpendlt(true);
};

const handleOkdltdlt = () => {
  setIsModalOpendlt(false);
};

const handleCanceldlt = () => {
  setIsModalOpendlt(false);
};

const handledelete=()=>{
   
}
// view
const showModalview = () => {
    navigate("/insta");
}
  return (
    <>
      <Button type="primary" onClick={showModal}>
        ADD BOTTOM ADD
      </Button>
      <Modal title="TOP ADD" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <div className="topadd">
          <div>
            <form>
              <label>Title</label>
              <br />
              <input type="text" name="title" />
              <br />
              <label>Description</label>
              <br />
              <input type="text" name="description" />
              <br />
              <label>TOP ADD</label>
              <br />
              <Upload
                fileList={fileList}
                onChange={handleFileChange}
                onPreview={handleFilePreview}
                listType="picture-card"
              >
                {fileList.length >= 1 ? null : uploadButton}
              </Upload>
              <br />
              <button onClick={handleOk}>Submit</button>
            </form>
          </div>
        </div>
      </Modal>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Date</th>
              <th>Title</th>
              <th>Description</th>
              <th>Adds</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>2024-03-15</td>
              <td>Sample Title</td>
              <td>Sample Description</td>
              <td>Sample Adds</td>
              <td>
                <Button type="primary" onClick={showModaledit}>
                <EditOutlined />
                </Button> &nbsp; &nbsp;
                <Button type="primary" onClick={showModaldlt}>
                <DeleteOutlined />
                </Button>&nbsp; &nbsp;
                <Button type="primary" onClick={showModalview}>
                <EyeOutlined />
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
        <Modal title="EDIT" open={isModalOpenedit} onOk={handleOkedit} onCancel={handleCanceledit}>
        <div className="topadd">
          <div>
            <form>
              <label>Title</label>
              <br />
              <input type="text" name="title" />
              <br />
              <label>Description</label>
              <br />
              <input type="text" name="description" />
              <br />
              <label>TOP ADD</label>
              <br />
              <Upload
                fileList={fileList}
                onChange={handleFileChange}
                onPreview={handleFilePreview}
                listType="picture-card"
              >
                {fileList.length >= 1 ? null : uploadButton}
              </Upload>
              <br />
              <button onClick={handleOk}>Submit</button>
            </form>
          </div>
        </div>
      </Modal>
      <Modal title="DELETE" open={isModalOpendlt} onOk={handleOkdltdlt} onCancel={handleCanceldlt}>
      <p>Are You Sure to Delete this</p>
      <button type="primary" className='primary' onClick={handledelete}>Delete</button>
      </Modal>
      </div>
    </>
  );
};

export default BottomAdd;

