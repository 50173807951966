import React from 'react'

function Dashbord() {
  return (
    <div>
        <div className='container'>
      <div className='row'>
<div className='col-4'>
<div className='dashbord'>
    TOPADD
</div>
</div>
<div className='col-4'>
<div className='dashbord'>
    BOTTOMADD
</div>
</div>
<div className='col-4'>
  <div className='dashbord'>
    VIDEO
    </div>
</div>
      </div>
    </div>
    </div>
  )
}

export default Dashbord
