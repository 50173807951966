import React, { useState } from 'react';
import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Button, theme } from 'antd';
import Dashbord from './Dashbord';
import TopAdd from './TopAdd';
import Video from './Video';
import BottomAdd from './BottomAdd';
import Profile from './Profile';

const { Header, Sider, Content } = Layout;

function MainContainer() {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKey, setSelectedKey] = useState('dashboard'); // Default selected key

  const handleMenuClick = (e) => {
    setSelectedKey(e.key);
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  // Render content based on selected key
  const renderContent = () => {
    switch (selectedKey) {
      case 'dashboard':
        return <div><Dashbord/></div>;
      case 'topadd':
        return <div><TopAdd/></div>;
      case 'bottomadd':
        return <div><BottomAdd/></div>;
      case 'video':
        return <div><Video/></div>;
      case 'profile':
        return <div><Profile/></div>;
      default:
        return null;
    }
  };

  return (
    <div className='sidebarmenu'>
      <Layout>
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className="demo-logo-vertical" />
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[selectedKey]}
            onClick={handleMenuClick}
          >
            <Menu.Item key='dashboard' icon={<UserOutlined />}>
              DASHBOARD
            </Menu.Item>
            <Menu.Item key='topadd' icon={<UserOutlined />}>
              TOP ADD
            </Menu.Item>
            <Menu.Item key='bottomadd' icon={<VideoCameraOutlined />}>
              BOTTOM ADD
            </Menu.Item>
            <Menu.Item key='video' icon={<UploadOutlined />}>
              VIDEO
            </Menu.Item>
            <Menu.Item key='profile' icon={<UserOutlined />}>
              PROFILE
            </Menu.Item>
            <Menu.Item key='logout' icon={<LogoutOutlined />}>
            Logout
            </Menu.Item>
          </Menu>
          {/* <button  type="primary" className='primary'>logout</button> */}
        </Sider>
        
        <Layout>
          <Header style={{ padding: 0, background: colorBgContainer }}>
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: '16px',
                width: 64,
                height: 64,
              }}
            />
          </Header>
          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
              height: "auto",
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            {renderContent()}
          </Content>
        </Layout>
       
      </Layout>
    </div>
  );
}

export default MainContainer;
