import "./App.css";
import FormComponent from "./components/Data";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeComponent from "./components/HomeComponent";
import MyApp from "./Myapp";
import LoginForm from "./components/Login";
import MainContainer from "./components/Admin/MainContainer";
import Signup from "./components/Admin/Signup";
import Signupuser from "./components/Signupuser";
import Videouser from "./components/Videouser";



function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeComponent />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/dashbord" element={<MainContainer />} />
        <Route path="/register" element={<Signupuser />} />
        {/* <Route path="/data" element={<FormComponent />} /> */}
        {/* <Route path="/myapp" element={<MyApp />} /> */}
        {/* <Route path="/signup" element={<Signup />} /> */}
        {/* <Route path="/videouser" element={<Videouser />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
