import React, { useEffect, useRef, useState } from "react";
import Short from "./Short";
// import data from "../data/data.json";

function ShortContainer() {
  const shortContainerRef = useRef();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const publicUrl = "https://en.kkhs.in";

  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${publicUrl}/api/dashbord`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        setData(jsonData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
   <section ref={shortContainerRef} className="short-container">
   {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          {data.map((value, index) => (
    <React.Fragment key={index}>
      <div className="adddiv"><img src="images/logo.png" className="logo" alt="" /></div> 
      <Short
        shortContainerRef={shortContainerRef}
        short={value}
      />
      <div className="adddiv"><img src="images/logo.png" className="logo" alt="" /> </div>
    </React.Fragment>
   ))}
   </div>
 )}
</section>


      <div className="navigation-container">
        <div
          onClick={() => {
            shortContainerRef.current.scrollTo(
              0,
              shortContainerRef.current.scrollTop - window.innerHeight
            );
          }}
          className="nav-up"
        >
          <ion-icon name="arrow-up-outline"></ion-icon>
        </div>
        <div
          onClick={() => {
            shortContainerRef.current.scrollTo(
              0,
              shortContainerRef.current.scrollTop + window.innerHeight
            );
          }}
          className="nav-down"
        >
          <ion-icon name="arrow-down-outline"></ion-icon>
        </div>
      </div>
    </>
  );
}

export default ShortContainer;
