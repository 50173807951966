import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, Upload } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const Video = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [vidData, setVidData] = useState([]);
  const navigate = useNavigate();

  const [file, setFile] = useState();
  const [vid, setVid] = useState();
  const [username, setUsername] = useState();
  const [title, setTitle] = useState();
  const [newLikes, setNewLikes] = useState();
  const fileInputRef = useRef(null);
  const vidInputRef = useRef(null);
  const [delId, setDelId] = useState();
  const publicUrl = "https://en.kkhs.in";
  const [editId, setEditId] = useState();




  const fetchData = async () => {
    try {
      const response = await fetch(`${publicUrl}/api/dashbord`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const Data = await response.json();
      setVidData(Data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);





  const handleData = async () => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('vid', vid);
      formData.append('username', username);
      formData.append('title', title);
      formData.append('newLikes', newLikes);
      console.log(newLikes);
      const response = await fetch(`${publicUrl}/api/upload`, {
        method: "POST",
        body: formData,
      })
      if (response.ok) {
        setUsername("");
        setTitle("");
        setNewLikes("");
        setFile(null);
        setVid(null);
        fileInputRef.current.value = '';
        vidInputRef.current.value = '';
        setIsModalOpen(false);
        fetchData();

      } else alert("Please Try again");
      // console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleFileChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const handleFilePreview = async file => {
    try {
      if (!file.url && !file.preview) {
        const preview = await getBase64(file.originFileObj);
        file.preview = preview;
        setFileList([...fileList]); // Ensure to update fileList to trigger re-render
      }
      window.open(file.preview); // Open the preview directly
    } catch (error) {
      console.error("Error occurred while generating file preview:", error);
    }
  };


  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  );


  //   edit 
  const [isModalOpenedit, setIsModalOpenedit] = useState(false);

  const showModaledit = async (id) => {
    setEditId(id);
    setIsModalOpenedit(true);
    for (let element of vidData) {
      if (element._id == id) {
        setUsername(element.username);
        setTitle(element.title);
        setNewLikes(element.newLikes);
        setFile(element.profileUrl);
        setVid(element.videoUrl)
      }
    }
  };

  const handleOkedit = () => {
    setIsModalOpenedit(false);
    setUsername('');
    setTitle('');
    setNewLikes('');
  };

  const handleCanceledit = () => {
    setIsModalOpenedit(false);
    setUsername('');
    setTitle('');
    setNewLikes('');
  };

  const updateVid = async (id) => {
    try {
      const formData = new FormData();
       if (file) {
         formData.append('file', file);
      }
      if (vid) {
         formData.append('vid', vid);
      }
      formData.append('username', username);
      formData.append('title', title);
      formData.append('newLikes', newLikes);
      const edit = await fetch(`${publicUrl}/api/update/${id}`, {
        method: "PUT",
        body: formData
      });
      if (edit.ok) {
        alert(`this Value has been Updated`);
        fetchData();
        setUsername('');
        setTitle('');
        setNewLikes('');
        setIsModalOpenedit(false);
      } else alert("Error In Update");

    } catch (error) {
      console.log(error);
    }
  }


  // Delete
  const [isModalOpendlt, setIsModalOpendlt] = useState(false);

  const showModaldlt = (id) => {
    setIsModalOpendlt(true);
    setDelId(id);
  };

  const handleOkdltdlt = () => {
    setIsModalOpendlt(false);
  };

  const handleCanceldlt = () => {
    setIsModalOpendlt(false);
  };
  const handledelete = async (id) => {
    try {
      console.log(id);
      const del = await fetch(`${publicUrl}/api/delete/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json"
        }
      });
      // console.log(del.ok);
      if (del.ok) {
        alert(`this Value has been Deleted`);
        fetchData();
        setIsModalOpendlt(false);
      } else alert("Error In Delete");
    } catch (error) {
      console.log(error);
    }
  }

  // view
  const showModalview = () => {
    navigate("/insta");
  }

  const handlenewLikes = () => {
    if (newLikes > 999) {
      setNewLikes(999);
    } else if (newLikes === 0 || newLikes < 0) {
      setNewLikes(1);
    }
  }

  useEffect(() => {
    handlenewLikes();
  }, [newLikes])

  return (
    <>
      <Button type="primary" onClick={showModal}>
        ADD VIDEO
      </Button>
      <Modal title="TOP ADD" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <div className="topadd">
          <div>
            <label>User Name</label>
            <br />
            <input type="text" name="username" onChange={(e) => setUsername(e.target.value)} value={username} />
            <br />
            <label>Title</label>
            <br />
            <input type="text" name="title" onChange={(e) => setTitle(e.target.value)}
              value={title} />
            <br />
            <label>Like</label>
            <br />
            <input type="number" name="newLikes" onChange={(e) => setNewLikes(e.target.value)}
              value={newLikes} />
            <br />
            <label>ADD PROFILE PIC</label>
            <br />
            <input accept='image/*' type="file" name="image" onChange={(e) => setFile(e.target.files[0])} ref={fileInputRef} />
            <br />
            <label>ADD VIDEO</label>
            <br />
            <input accept='video/*' type="file" name="vid" onChange={(e) => setVid(e.target.files[0])} ref={vidInputRef} />
            <br />
            <button onClick={handleData}>Submit</button>
          </div>
        </div>
      </Modal>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Username</th>
              <th>Title</th>
              <th>Likes</th>
              <th>Profile Img</th>
              <th>Video</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {vidData.map((value, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{value.username}</td>
                  <td>{value.title}</td>
                  <td>{`${value.newLikes}k`}</td>
                  <td><img src={`${publicUrl}/server/${value.profileUrl}`} alt="" width={50} /></td>
                  <td>
                    <video
                      autoPlay
                      disableRemotePlayback
                      preload=''
                      playsInline
                      loop
                      muted
                      style={{ width: '50px' }}
                      src={`${publicUrl}/server/${value.videoUrl}`}
                    >
                    </video>
                  </td>
                  <td>
                    <Button type="primary" onClick={() => {
                      showModaledit(value._id);
                    }}>
                      <EditOutlined />
                    </Button> &nbsp; &nbsp;
                    <Button type="primary" onClick={() => {
                      showModaldlt(value._id);
                    }}>
                      <DeleteOutlined />
                    </Button>&nbsp; &nbsp;
                    <Button type="primary" onClick={showModalview}>
                      <EyeOutlined />
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <Modal title="EDIT" open={isModalOpenedit} onOk={handleOkedit} onCancel={handleCanceledit}>
          <div className="topadd">
            <div>
              <label>User Name</label>
              <br />
              <input type="text" name="username" onChange={(e) => setUsername(e.target.value)} value={username} />
              <br />
              <label>Title</label>
              <br />
              <input type="text" name="title" onChange={(e) => setTitle(e.target.value)}
                value={title} />
              <br />
              <label>Like</label>
              <br />
              <input type="number" name="newLikes" onChange={(e) => setNewLikes(e.target.value)}
                value={newLikes} />
              <br />
              <label>ADD PROFILE PIC</label>
              <br />
              <input accept='image/*' type="file" name="image" onChange={(e) => setFile(e.target.files[0])} ref={fileInputRef} />
              <img src={`${publicUrl}/server/${file}`} width={50} />
              <br />
              <label>ADD VIDEO</label>
              <br />
              <input accept='video/*' type="file" name="vid" onChange={(e) => setVid(e.target.files[0])} ref={vidInputRef} />
              <video
                autoPlay
                disableRemotePlayback
                preload=''
                playsInline
                loop
                muted
                style={{ width: '50px' }}
                src={`${publicUrl}/server/${vid}`}
              >
              </video>
              <br />
              <button onClick={() => {
                updateVid(editId);
              }}>Submit</button>
            </div>
          </div>
        </Modal >
        <Modal title="DELETE" open={isModalOpendlt} onOk={handleOkdltdlt} onCancel={handleCanceldlt}>
          <p>Are You Sure to Delete this</p>
          <button type="primary" className='primary' onClick={() => {
            handledelete(delId);
          }}>Delete</button>
        </Modal>
      </div >
    </>
  );
};

export default Video;

