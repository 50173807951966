import React, { useState } from 'react';
import { NavLink, useHistory, useNavigate } from 'react-router-dom'; // Assuming you're using React Router for navigation

function LoginForm() {
  const [userData, setuserData] = useState("");

  const heandletoLogin = (e) => {

  }
  const handleToChangelogin = (e) => {
    const { name, value } = e?.target;
    setuserData({ ...userData, [name]: value });
  };
  const handleToChange = (e) => {
    const { name, value } = e?.target;
    setuserData({ ...userData, [name]: value });
  };

  return (

    <div className='signupform'>

      <input type="radio" id="loginForm" name="formToggle" checked />

      <input type="radio" id="registerForm" name="formToggle" />

      <input type="radio" id="forgotForm" name="formToggle" />

      <div class="wrapper" id="loginFormContent">
        <span className='logosignup'><img src="images/logo.png"></img></span>
        <form action="">

          <h1>Login</h1>

          <div class="input-box">

            <input type="text" name="username" onChange={(e) => { handleToChangelogin(e); }} placeholder="Username" required />



          </div>

          <div class="input-box">

            <input type="password" name="password" onChange={(e) => { handleToChangelogin(e); }} placeholder="Password" required />



          </div>

          <div class="checkbox1">

            <label><input type="checkbox" />Remember Me</label>
 
            {/* <label for="forgotForm">Forgot Password</label> */}

          </div>

          <button type="submit" onClick={(e) => { heandletoLogin(e); }} class="btn">Login</button>

          <div class="link">

            <p>Don't have an account? <NavLink to='/register'>Register</NavLink></p>

          </div>

        </form>

      </div>
      </div>
      );
}

      export default LoginForm;
