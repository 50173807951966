import React, { useState } from 'react'

function Signupuser() {
    const DEBUG = true;
    const [userData, setuserData] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0]);
      };

      const handleToChange = (e) => {
        const { name, value } = e?.target;
        setuserData({ ...userData, [name]: value });
      };
      const handleToChangeRequest = (e) => {
        const { name, value } = e?.target;
        setuserData({ ...userData, [name]: value });
      };

    const heandletoRequest=(e)=>{

    }
    const heandletoLogin=(e)=>{

    }
    const heandletoRegister=(e)=>{

    }
  return (
    <div>
      <div className='signupform'>
        
  <input type="radio" id="loginForm" name="formToggle" checked/>

<input type="radio" id="registerForm" name="formToggle"/>

<input type="radio" id="forgotForm" name="formToggle"/>

  

  {/* <div class="wrapper" id="loginFormContent">
  <span className='logosignup'><img src="images/logo.png"></img></span>
    <form action="">

      <h1>Login</h1>

      <div class="input-box">

        <input type="text"  name="username" onChange={(e)=>{handleToChangelogin(e);}} placeholder="Username" required/>

        

      </div>

      <div class="input-box">

        <input type="password"  name="password" onChange={(e)=>{handleToChangelogin(e);}} placeholder="Password" required/>

        

      </div>

      <div class="checkbox1">

        <label><input type="checkbox"/>Remember Me</label>

        <label for="forgotForm">Forgot Password</label>

      </div>

      <button type="submit" onClick={(e) => {heandletoLogin(e);}} class="btn">Login</button>

      <div class="link">

        <p>Don't have an account? <label for="registerForm">Register</label></p>

      </div>

    </form>

  </div> */}

  <div class="wrapper" id="registerFormContent">
  <span className='logosignup'><img src="images/logo.png"></img></span>
    <form action="">

      <h1>Register</h1>

      <div class="input-box">

        <input type="text"  name='username' onChoose={(e)=>{handleToChange(e);}} placeholder="Username" required/>

        

      </div>

      <div class="input-box">

        <input type="email" name='email' onChoose={(e)=>{handleToChange(e);}} placeholder="Email" required/>

      </div>

   
      <div class="input-box">

        <input type="password"  name='password' onChoose={(e)=>{handleToChange(e);}} placeholder="Password" required/>

      </div>
      <div class="input-box">

<input type="file" name="profile" onChoose={handleFileSelect}></input>
</div>
      <div class="checkbox1">

        <label><input type="checkbox" required/>I agree to terms & conditions</label>

      </div>

      <button type="submit" onClick={(e) => {
            heandletoRegister(e);
          }} class="btn">Register</button>

      <div class="link">

        <p>Already have an account? <label for="loginForm">Login</label></p>

      </div>

    </form>

  </div>

  <div class="wrapper" id="forgotFormContent">
  <span className='logosignup'><img src="images/logo.png"></img></span>
  <form action="">

      <h1>Reset your password</h1>

      <div class="input-box">

        <input type="email" name="email" onChange1={(e)=>{handleToChangeRequest(e);}} placeholder="Email" required/>

      </div>

      <div class="input-box">

      <button type="submit" onClick={(e) => {
            heandletoRequest(e);
          }} class="btn">Send Request</button>
       </div>
      <div class="link">

        <p>Don't have an account? <label for="registerForm">Register</label></p>

      </div>

    </form>

</div>
      </div>
    </div>
  )
}

export default Signupuser
