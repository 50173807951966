import { useEffect, useRef, useState } from "react";
import { Button, Modal } from 'antd';

function Short({ short, shortContainerRef }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLiked, setIsLiked] = useState(short.isLiked);
  const [isMuted, setIsMuted] = useState(true)
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState('Content of the modal');
  const [openshare, setOpenshare] = useState(false);
  const [w2, setW2] = useState(false);
  const videoRef = useRef();
  const assetsUrl = "https://en.kkhs.in/server";

  const getBotReply = (userInput) => {
    const replies = [
      "I'm sorry, I didn't understand that.",
      "That's interesting!",
      "How can I assist you further?",
      "I'm not sure what you mean.",
      "Can you please provide more details?",
      "Sure!",
      "No problem!",
      "Yes, that's correct.",
      "I appreciate your feedback!",
      "Thank you!"
    ];
    const randomIndex = Math.floor(Math.random() * replies.length);
    return replies[randomIndex];
  };

  const sendMessage = (e) => {
    e.preventDefault();
    if (inputValue.trim() === '') return;

    setMessages(prevMessages => [...prevMessages, { type: 'user', text: inputValue }]);
    setInputValue('');

    setTimeout(() => {
      setMessages(prevMessages => [...prevMessages, { type: 'bot', text: getBotReply(inputValue) }]);
    }, 1000);
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setModalText('The modal will be closed after two seconds');
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleCancelshare = () => {
    setOpenshare(false);
  };

  const showModal1 = () => {
    setOpenshare(true);
  };

  const handleOkshare = () => {
    setModalText('The modal will be closed after two seconds');
    setConfirmLoading(true);
    setTimeout(() => {
      setOpenshare(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleMute = () => {
    if (!isMuted) {
      setIsMuted(true);
      videoRef.current.muted = true;
      setW2(false);
    } else {
      setIsMuted(false);
      videoRef.current.muted = false;
      setW2(true);
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // debugger;
          if (entry.isIntersecting) {

            if (w2) {
              setIsMuted(false);
            }
            // debugger;
            videoRef.current.muted = isMuted;
            videoRef.current.play();
            setIsPlaying(true);

          } else {
            videoRef.current.currentTime = 0;
            videoRef.current.pause();
            setIsPlaying(false);
            if (w2) {
              setIsMuted(false);
            }
            else{
            setIsMuted(true);}
          }
        });
      },
      { threshold: 0.5 }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, [isMuted]);

  return (
    <div className="reel">
      <div className="reel-video">
        <div className="video">
          <video
            ref={videoRef}
            onClick={function (e) {
              if (!isPlaying) {
                videoRef.current.play();
                setIsPlaying(true);
              } else {
                videoRef.current.pause();
                setIsPlaying(false);
              }
            }}
            disableRemotePlayback
            playsInline
            loop
            muted  // Ensure video is muted initially
            src={`${assetsUrl}/${short.videoUrl}`}
          ></video>
        </div>
        <button
          className="muteBtn"
          onClick={(e) => {
            handleMute()
          }}
        >{
            isMuted === true ?
              <ion-icon name="volume-mute"></ion-icon> :
              <ion-icon name="volume-high"></ion-icon>
          }
        </button>
        <div className="foot">
          <div className="title">{short.title}</div>
          <div className="user-info">
            <div>
              <img src={`${assetsUrl}/${short.profileUrl}`} alt="" />
              <span>{short.username}</span>
            </div>
            {!short.isFollowed && <button>Follow</button>}
          </div>
        </div>
        <div className="reaction">
          <div onClick={() => setIsLiked(!isLiked)}>
            {isLiked ? (
              <span className="like">
                <ion-icon name="heart"></ion-icon>
              </span>
            ) : (
              <span className="unlike">
                <ion-icon name="heart-outline"></ion-icon>
                <br />
              </span>
            )}
            <span className="value">{`${short.newLikes}k`}</span>
          </div>
          <div>
            <ion-icon name="chatbubble-outline" onClick={showModal}></ion-icon>
            <span className="value">{short.comments}</span>
            <Modal
              open={open}
              onOk={handleOk}
              confirmLoading={confirmLoading}
              onCancel={handleCancel}
            >
              <div className="chat-body">
                {messages.map((message, index) => (
                  <div key={index} className={`chat-message chat-message-${message.type}`} style={{ marginBottom: '10px', padding: '6px', borderRadius: '5px', backgroundColor: message.type === 'bot' ? '#f95959' : '#233142', color: '#fff', textAlign: message.type === 'bot' ? 'left' : 'right' }}>
                    {message.text}
                  </div>
                ))}
              </div>
              <div className="chat-footer">
                <form onSubmit={sendMessage}>
                  <div className="input-group">
                    <input type="text" className="form-control" placeholder="Type your message..." value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
                    <button type="submit" className="send-button">Send</button>
                  </div>
                </form>
              </div>
            </Modal>
          </div>
          <div className="sharebtn">
            <ion-icon name="arrow-redo-outline" onClick={showModal1}></ion-icon>
            <Modal
              open={openshare}
              onOk={handleOkshare}
              confirmLoading={confirmLoading}
              onCancel={handleCancelshare}
            >
              <div className="content">
                <p>Share this link via</p>
                <p>Or copy link</p>
                <div className="field">
                  <i className="url-icon uil uil-link"></i>
                  <input type="text" readOnly value={`https://?url=${encodeURIComponent(short.videoUrl)}`} />
                  <button>Copy</button>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Short;
